import './src-concat/01_modernizr.min.js'
import Headroom    from './src-concat/02_headroom.min.js'
import Flexslider  from './src-concat/02_jquery.flexslider-min.js'
import Waypoint    from './src-concat/02_jquery.waypoints.min.js'
import './src-concat/03_lazysizes.min.js'
import './src-concat/04_magnific-popup.min.js'
import './src-concat/05_cssua.min.js'

(function($, window, document, undefined) {

  'use strict';

  /**
   * This is a description for Mangrove object.
   *
   * @namespace Mangrove
   * @author Mangrove
   */

  var Mangrove = {

    /**
     * Initialize object
     * @function init
     * @memberof Mangrove
     */

    init: function() {

      this._cache();
      this._events();
      this._initHeadroom();
      this._initPopups();
      this._initFlexslider();
      this._initWaypoints();
      this._showPrevIfNoNext();
      this._showHero();
      this._initTabs();
      this._initSmoothScroll();

    }, // init()

    /**
     * Cache reusable data
     * @function cache
     * @memberof Mangrove
     */

    _cache: function() {

      this.$win                = $(window);
      this.$html               = $('html');
      this.$body               = $('body');
      this.$mainWrap           = $('main');
      this.$header             = $('.js-header');
      this.$mobileNav          = $('.js-mobile-nav');
      this.$hamburger          = $('.js-hamburger');
      this.$flexsldier         = $('.flexslider');
      this.$contactNav         = $('.nav--mobile .menu-item:last-child');
      this.$projectCat         = $('.proj-list__cat');

    }, // _cache()

    /**
     * Attach event listeners
     * @function _events
     * @memberof Mangrove
     */

    _events: function() {

      this.$html.on(
          'click',
          '.js-hamburger',
          this._showMobileNav.bind(this)
      );
      this.$contactNav.on(
        'click',
        this.$contactNav,
        this._showContactInfo.bind(this)
      );
      $(document).on(
        'scroll',
        this._showHero.bind(this)
      )
      $(window).load(function() {
        $("body").removeClass("preload");
      });
      this.$projectCat.click(function() {
        $(".proj-list__nav").addClass('animated--slow fadeIn');
        $(".proj-list__item").addClass('bb-fade');
      });
      $('.js-down').click(function() {
        $('html, body').animate({
          scrollTop: $(".bb-hero").next().offset().top
        }, 1000);
        return false;
      });
      $('.js-team a').click(function() {
        setTimeout(function(){
          $("body").addClass("popup-open");
        }, 500);
      });
      $('body.nav-active').on('scroll', function(e) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      });
    }, // _events()


    _initPopups: function() {
      $('.js-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        removalDelay: 100,
        mainClass: 'popup--animate'
      });
      $('.js-team').magnificPopup({
        delegate: 'a',
        type: 'inline',
        midClick: true,
        removalDelay: 100,
        mainClass: 'popup--animate'
      });
    },

    _initHeadroom: function() {
      var theHeader = document.querySelector('.js-header');
      var headroom = new Headroom(theHeader);
      headroom.init();
    },

    _initSmoothScroll: function() {
      $('.js-smoothscroll').click(function () {
        $('html, body').animate({
          scrollTop: $("#recent-press").offset().top - 100
        }, 500);
        return false;
      });
    },

    _initTabs: function() {
            /*
      *   This content is licensed according to the W3C Software License at
      *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
      *
      *   File:   tabs-automatic.js
      *
      *   Desc:   Tablist widget that implements ARIA Authoring Practices
      */

      'use strict';

      class TabsAutomatic {
        constructor(groupNode) {
          this.tablistNode = groupNode;

          this.tabs = [];

          this.firstTab = null;
          this.lastTab = null;

          this.tabs = Array.from(this.tablistNode.querySelectorAll('[role=tab]'));
          this.tabpanels = [];

          for (var i = 0; i < this.tabs.length; i += 1) {
            var tab = this.tabs[i];
            var tabpanel = document.getElementById(tab.getAttribute('aria-controls'));

            tab.tabIndex = -1;
            tab.setAttribute('aria-selected', 'false');
            this.tabpanels.push(tabpanel);

            tab.addEventListener('keydown', this.onKeydown.bind(this));
            tab.addEventListener('click', this.onClick.bind(this));

            if (!this.firstTab) {
              this.firstTab = tab;
            }
            this.lastTab = tab;
          }

          this.setSelectedTab(this.firstTab, false);
        }

        setSelectedTab(currentTab, setFocus) {
          if (typeof setFocus !== 'boolean') {
            setFocus = true;
          }
          for (var i = 0; i < this.tabs.length; i += 1) {
            var tab = this.tabs[i];
            if (currentTab === tab) {
              tab.setAttribute('aria-selected', 'true');
              tab.removeAttribute('tabindex');
              this.tabpanels[i].classList.remove('is-hidden');
              if (setFocus) {
                tab.focus();
              }
            } else {
              tab.setAttribute('aria-selected', 'false');
              tab.tabIndex = -1;
              this.tabpanels[i].classList.add('is-hidden');
            }
          }
        }

        setSelectedToPreviousTab(currentTab) {
          var index;

          if (currentTab === this.firstTab) {
            this.setSelectedTab(this.lastTab);
          } else {
            index = this.tabs.indexOf(currentTab);
            this.setSelectedTab(this.tabs[index - 1]);
          }
        }

        setSelectedToNextTab(currentTab) {
          var index;

          if (currentTab === this.lastTab) {
            this.setSelectedTab(this.firstTab);
          } else {
            index = this.tabs.indexOf(currentTab);
            this.setSelectedTab(this.tabs[index + 1]);
          }
        }

        /* EVENT HANDLERS */

        onKeydown(event) {
          var tgt = event.currentTarget,
            flag = false;

          switch (event.key) {
            case 'ArrowLeft':
              this.setSelectedToPreviousTab(tgt);
              flag = true;
              break;

            case 'ArrowRight':
              this.setSelectedToNextTab(tgt);
              flag = true;
              break;

            case 'Home':
              this.setSelectedTab(this.firstTab);
              flag = true;
              break;

            case 'End':
              this.setSelectedTab(this.lastTab);
              flag = true;
              break;

            default:
              break;
          }

          if (flag) {
            event.stopPropagation();
            event.preventDefault();
          }
        }

        onClick(event) {
          this.setSelectedTab(event.currentTarget);
        }
      }

      // Initialize tablist

      window.addEventListener('load', function () {
        var tablists = document.querySelectorAll('[role=tablist].js-tabs');
        for (var i = 0; i < tablists.length; i++) {
          new TabsAutomatic(tablists[i]);
        }
      });
    },

    _showMobileNav: function(e) {
      e.preventDefault();
      setTimeout(function(){
          $(".js-mobile-nav ul").toggleClass("cascade");
      }, 200);
      this.$mobileNav.toggleClass('is-active');
      this.$hamburger.toggleClass('is-active');
      $('body').toggleClass('nav-active');
      if ( this.$mobileNav.hasClass('is-active') ) {
        this.$header.addClass('nav--white');
        $('.home .headroom--top').css('opacity', '1');
      } else {
        $('.home .headroom--top').css('opacity', '');
        $('.contact__grid.contact--header').removeClass('is-active');
        this.$contactNav.removeClass('is-active');
        if ( this.$header.hasClass('nav--white') && this.$header.hasClass('nav--color') ) {
          this.$header.removeClass('nav--white');
        }
      }

    },

    _showContactInfo: function(e) {
      e.preventDefault();
      $('.contact__grid.contact--header').toggleClass('is-active');
      this.$contactNav.toggleClass('is-active');
    },

    _initFlexslider: function(e) {
      $('.flexslider').flexslider({
        animation:  Modernizr.touch     ? "slide" : "fade",
        animationSpeed: Modernizr.touch ? 200 : 4000,
        slideshowSpeed : 4500,
        controlNav : false,
        directionNav : false,
        fadeFirstSlide: false
      });
      $('.js-services').flexslider({
        animation: "slide",
        controlNav: false,
        directionNav: true,
      });
    },

    _showPrevIfNoNext: function(e) {
      if ( $('.nav-previous').length <= 0 ) {
        $('.nav-next').css('display', 'block');
      }
    },

    _showHero: function(e) {

      var initalScrollPoint = 25
      var secondaryScrollPoint = 200

      // only do this on pages with a bb-hero
      if ( $('.bb-hero').length ) {

        // when we scroll past 25pxs....
        if ( $(document).scrollTop() >= initalScrollPoint ) {

          // hide the bb-hero content
          $('.bb-hero').removeClass('hero__content--is-shown');
          $('.bb-hero').addClass('hero__content--is-hidden');

          // if the body has shown class, remove that class and scroll to the next element
          if ( $('body.home').hasClass('body--show-hero') ) {
            $('body.home').removeClass('body--show-hero');
            $('html, body').animate({
              scrollTop: $(".bb-hero").next().offset().top
            }, 1000);
          }

        // when we scroll back to the very top...
        } else {

          // show the hero content again
          $('.bb-hero').removeClass('hero__content--is-hidden');
          $('.bb-hero').addClass('hero__content--is-shown');

          // change the nav to white on home
          $('.home .js-header').removeClass('nav--color');
          $('.home .js-header').addClass('nav--white');

          // change the nav to white on single projects
          //$('.single-project .js-header').removeClass('nav--color');
          //$('.single-project .js-header').addClass('nav--white');

          // add the class again to lock the body
          $('body.home').addClass('body--show-hero');
        }

        // when we scroll down a bit, remove the hero itself (otherwise you lose animation and bb-hero covers all content)
        if ($(document).scrollTop() >= secondaryScrollPoint) {
          $('.home .bb-hero').removeClass('hero--is-shown');
        } else {
          $('.home .bb-hero').addClass('hero--is-shown');
        }

      }
    },

    _initWaypoints: function(e) {

      // if the section has a class of white, change logo to white
      var waypoints = $('.js-logo--white').waypoint(function(direction) {
          if (direction == 'down') {
            $('.js-header').removeClass('nav--color');
            $('.js-header').addClass('nav--white');
          }
      },{
        offset: 42
      })
      var waypoints = $('.js-logo--color').waypoint(function(direction) {
          if (direction == 'down') {
            $('.js-header').removeClass('nav--white');
            $('.js-header').addClass('nav--color');
          }
      },{
        offset: 42
      })

      var waypoints = $('.js-logo--white').waypoint(function(direction) {
          if (direction == 'up') {
            $('.js-header').removeClass('nav--color');
            $('.js-header').addClass('nav--white');
          }
      },{
        offset: function() {
          return -this.element.clientHeight
        }
      })
      var waypoints = $('.js-logo--color').waypoint(function(direction) {
          if (direction == 'up') {
            $('.js-header').removeClass('nav--white');
            $('.js-header').addClass('nav--color');
          }
      },{
        offset: function() {
          return -this.element.clientHeight
        }
      })


      // FadeIn -- Offset 50% -- Normal
      var waypoints = $('.single-img__caption, .single-project .bb-hero').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('animated fadeIn');
      },{
        offset: '50%'
      })

      // FadeIn -- Offset 60% -- Slow
      var waypoints = $('.team__img, .colored-text__item, .bb-basic_text, .team__intro, .proj-list__nav').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('animated--slow fadeIn');
      },{
        offset: '60%'
      })

      // FadeIn -- Offset 65% -- Normal
      var waypoints = $('.proj-list__item:not(.team__img)').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('bb-fade');
      },{
        offset: '85%'
      })

      // FadeIn -- Offset 70% -- Normal
      var waypoints = $('.project__content, .project__features').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('animated fadeIn');
      },{
        offset: '70%'
      })


      // FadeIn -- Offset 80% -- Slow
      var waypoints = $('.full-project__image, .bb-single_image, .gallery__inner').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('animated--slow fadeIn');
      },{
        offset: '80%'
      })

      // FadeIn -- Offset 90% -- Normal
      var waypoints = $('.nav-links, .post-grid-info').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('animated fadeIn');
      },{
        offset: '90%'
      })

      //  Show Project Names on Mobile
      var waypoints = $('.proj-list__item').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('in-view');
      },{
        offset: 'bottom-in-view'
      })

      //  FadeIn -- Bottom in View
      var waypoints = $('.entry-header').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('animated fadeIn');
      },{
        offset: 'bottom-in-view'
      })

      // Fade / Slide from Right
      var waypoints = $('.full-project__title').waypoint(function(direction) {
        var elem = this.element;
        $(elem).addClass('animated--slow fadeInRight');
      },{
        offset: 'bottom-in-view'
      })

    }


  }; // Mangrove

  Mangrove.init();

})(jQuery, window, document);
